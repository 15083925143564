import classNames from 'classnames'
import * as React from 'react'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import XIcon from 'wix-ui-icons-common/X'
import ConfirmIcon from 'wix-ui-icons-common/Confirm'
import TagIcon from 'wix-ui-icons-common/Tag'
import {DH} from '../../../constants/data-hooks'
import * as fs from '../../form/form.scss'
import {CouponError} from '../coupon-error'
import * as c from '../../classnames.scss'
import * as s from './coupon-input.scss'
import {CouponInputProps, CouponInputState} from '.'

const borderClass = c.formSummaryBox
const colorClass = c.formSummaryColor
const fontClass = c.formSummaryBody
const inputClass = c.formSummaryCouponInput
const submitClass = c.formSummaryCouponSubmit
const cancelClass = c.formSummaryCouponCancel

export class CouponInput extends React.Component<CouponInputProps, CouponInputState> {
  state = {shouldInfoTextShow: !this.props.couponCode}

  submit = () => {
    const {couponCode, eventId, reservationId, applyCoupon} = this.props

    if (couponCode.length) {
      applyCoupon(eventId, reservationId, couponCode)
    }
  }

  reset = (focus: boolean) => {
    this.setState({shouldInfoTextShow: !focus})
    this.props.resetCouponCode()
  }

  setCode = (code: string) => this.props.setCouponCode(code)

  handleInfoText = () => {
    if (this.props.couponCode) {
      return this.setState({shouldInfoTextShow: false})
    }

    this.setState({shouldInfoTextShow: !this.state.shouldInfoTextShow})
  }

  renderButton = () => {
    const {discountValid, orderCompleted} = this.props

    if (discountValid && !orderCompleted) {
      return (
        <button
          type="button"
          data-hook={DH.COUPON_CODE_CLEAR}
          onClick={() => this.reset(false)}
          className={classNames(borderClass, s.couponCancel, cancelClass)}
        >
          <XIcon className={s.couponCancelIcon} size="17px" />
        </button>
      )
    }

    if (!discountValid) {
      return (
        <button
          type="button"
          onClick={this.submit}
          data-hook={DH.COUPON_CODE_SUBMIT}
          className={classNames(s.couponSubmit, submitClass)}
        >
          <ChevronRight className={s.couponSubmitIcon} />
        </button>
      )
    }
  }

  render() {
    const {couponCode, discountValid, orderCompleted, showCouponInput, t, error} = this.props
    const StatusIcon = discountValid ? ConfirmIcon : TagIcon
    const isError = Boolean(error)

    if (!showCouponInput) {
      return null
    }

    return (
      <div className={classNames({[fs.summaryBlock]: !isError})}>
        <div className={classNames(s.couponInputContainer, colorClass)}>
          <div className={classNames(s.couponInfoContainer)}>
            <StatusIcon size="24px" />
            {this.state.shouldInfoTextShow && (
              <span className={classNames(s.couponOpenButtonText, fontClass)} data-hook={DH.COUPON_INPUT_INFO}>
                {t('summary.enterCouponCode')}
              </span>
            )}
          </div>
          <div data-hook={DH.COUPON_FORM}>
            <input
              type="text"
              maxLength={20}
              onFocus={() => (isError ? this.reset(true) : this.handleInfoText())}
              onBlur={this.handleInfoText}
              onChange={event => this.setCode(event.target.value)}
              onKeyPress={event => event.key === 'Enter' && this.submit()}
              data-hook={DH.COUPON_CODE_INPUT}
              className={classNames(borderClass, s.couponInput, inputClass, {
                [c.formSummaryBoxBorderColorActive]: !!couponCode?.length,
                [s.couponInputReadOnly]: orderCompleted,
              })}
              value={couponCode}
              readOnly={discountValid}
            />
            {this.renderButton()}
          </div>
          {isError && (
            <div data-hook={DH.COUPON_ERROR}>
              <CouponError error={error} t={t} />
            </div>
          )}
        </div>
      </div>
    )
  }
}
