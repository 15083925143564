import {getFormattedPrice} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {calculateSubPrice} from '../../../../services/payment'
import * as c from '../../../classnames.scss'
import {TableRow} from '../../../table-row'
import {FixedHeightColumn} from './fixed-height-column'
import {Price} from './price'
import {Quantity} from './quantity'
import {SoldOutLabel} from './sold-out-label'
import {TicketName} from './ticket-name'
import * as s from './ticket.scss'
import {Total} from './total'
import {TicketProps} from '.'

export const Ticket: React.FC<TicketProps> = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  t,
  isMobile,
  maxTicketsPerReservation,
}) => {
  const {id, limitPerCheckout, price} = ticket
  const total = getFormattedPrice(calculateSubPrice(Number(price.amount), selectedQuantity), price.currency)

  return (
    <TableRow
      config={{
        itemKey: id,
        responsiveHideColumns: [1],
        addDivider: true,
        className: classNames(s.row, c.evTextFont, c.evTextColor),
        dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
        dataHook: DH.TICKET,
        columns: [
          {
            className: s.nameColumn,
            content: (
              <div className={s.nameContainer}>
                <SoldOutLabel t={t} limit={limitPerCheckout} />
                <TicketName t={t} ticket={ticket} />
              </div>
            ),
          },
          {
            content: (
              <FixedHeightColumn ticketDefId={id}>
                <Price t={t} ticket={ticket} />
              </FixedHeightColumn>
            ),
          },
          {
            content: (
              <FixedHeightColumn ticketDefId={id}>
                {availableTicketsQuantity === 0 && !!selectedQuantity && (
                  <div className={classNames(s.tooltip, c.evTextFont, c.quantityPickerTooltip)} role="alert">
                    {maxTicketsPerReservation === 1
                      ? t('ticketLimitPerReservationReached_singular')
                      : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})}
                  </div>
                )}
                <Quantity
                  selectedQuantity={selectedQuantity}
                  availableTicketsQuantity={availableTicketsQuantity}
                  limit={limitPerCheckout}
                  disabled={!limitPerCheckout || registrationClosed}
                  onChange={onChange}
                  id={id}
                  t={t}
                  isMobile={isMobile}
                />
              </FixedHeightColumn>
            ),
          },
          {
            content: (
              <FixedHeightColumn className={s.total} ticketDefId={id}>
                <Total t={t} total={total} />
              </FixedHeightColumn>
            ),
          },
        ],
      }}
    />
  )
}
