import {getAvatars, getExtraGuestsCount, handleKeyPressEnterOrSpace, isTicketed} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import * as React from 'react'
import {AvatarGroup, AvatarGroupSize} from 'wix-ui-tpa/AvatarGroup'
import {TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import {DH} from '../../constants/data-hooks'
import s from './members.st.css'
import {MembersProps} from './index'

const MAX_AVATARS = 7
const MAX_ONLY_ANONYMOUS_AVATARS = 3

export const Members = ({totalGuests, members, event, mobile, openMembersModal, t}: MembersProps) => {
  const items = getAvatars(members, totalGuests, !isTicketed(event), MAX_AVATARS)
  const hasMemberAvatars = items.filter(item => item.name).length
  const hasMembers = members.length

  const maxAvatars = hasMemberAvatars ? MAX_AVATARS : MAX_ONLY_ANONYMOUS_AVATARS
  const extraGuests = getExtraGuestsCount(totalGuests, maxAvatars)
  const label =
    !hasMembers && extraGuests ? t('members.moreGuestsLink', {amount: extraGuests}) : t('members.seeAllGuestsLink')

  const onClick = () => openMembersModal(event.id)

  return (
    <div
      className={classNames(s.root, mobile ? s.mobile : '')}
      onClick={onClick}
      onKeyPress={handleKeyPressEnterOrSpace(onClick)}
      tabIndex={0}
      data-hook={DH.EVENT_DETAILS_MEMBERS}
    >
      <AvatarGroup
        {...{className: s.avatars}}
        maxAmount={maxAvatars}
        items={items}
        size={mobile ? AvatarGroupSize.medium : AvatarGroupSize.large}
      >
        <AvatarGroup.TextButton
          tabIndex={-1}
          className={s.link}
          priority={mobile ? TEXT_BUTTON_PRIORITY.link : TEXT_BUTTON_PRIORITY.primary}
        >
          {label}
        </AvatarGroup.TextButton>
      </AvatarGroup>
    </div>
  )
}
