import {hasImage, isRegistrationClosed, isTicketed} from '@wix/wix-events-commons-statics'
import {memberRsvpExists} from '../../../selectors/member-rsvp'
import {getButtonStyle, getTexts, isRsvpButtonVisible} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {isMobile} from '../../../../commons/selectors/environment'
import {RegistrationButtonOwnProps, RegistrationButtonRuntimeProps} from './interfaces'
import {RegistrationButton as Presentation} from './registration-button'

const mapRuntime = ({state, actions}: DetailsPageAppProps): RegistrationButtonRuntimeProps => ({
  registrationButtonVisible: isRsvpButtonVisible(state),
  registrationClosed: isRegistrationClosed(state.event),
  memberRsvpExists: memberRsvpExists(state),
  ticketedEvent: isTicketed(state.event),
  rsvpButtonText: getTexts(state.component.settings).rsvpButtonText,
  buttonStyle: getButtonStyle(state.component.settings),
  imageVisible: hasImage(state.event),
  navigateToMainPage: actions.navigateToMainPage,
  isMobile: isMobile(state),
})

export const RegistrationButton = connect<RegistrationButtonOwnProps, RegistrationButtonRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
